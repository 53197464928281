import React, {useEffect, useRef, useState} from 'react';
import {message, Modal, Spin, Upload,} from 'antd';
import * as Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import axios from 'axios';
import FileMD5 from '@utils/md5';
import {localStorageKeys} from '@/constants';

// const actionUrl = "https://fgateway.fat.ycrzky.com/tezzolo-finance-manager/common/file/upload";
const actionUrl = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_UPLOAD_URL;

const UploadFile = ({fileType, fileLists, fileChange, beforeUpload, multiple}) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [files, setFiles] = useState([]);
    const filesRef = useRef(files);

    useEffect(() => {
        if (fileLists) {
            setFiles(fileLists);
        }
    }, [fileLists]);

    useEffect(() => {
        filesRef.current = files;
    }, [files]);

    // 解析前端file的信息，给到后端
    const parseFiles = (targetFiles) => targetFiles.map((val) => {
        const newFileObj = {};
        newFileObj.fileKey = val.fileKey;
        newFileObj.url = val.url;
        newFileObj.md5 = val.md5;
        newFileObj.name = val.name;
        return newFileObj;
    });

    const checkPicture = (fileName) => /\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileName);

    const handlePreview = async (file) => {
        if (file.fileKey && checkPicture(file.fileKey)) {
            setPreviewImage(file.url || file.preview);
            setPreviewVisible(true);
            setPreviewTitle("图片查看");
        } else {
            window.open(file.url, '_blank');
        }
    };

    const handleCancel = () => setPreviewVisible(false);
    const onRemove = (file) => {
        Modal.confirm({
            title: '确定要删除这张图片吗？',
            content: '删除后将无法恢复，请确认是否删除。',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                const newFileList = [...(files.filter((val) => !(val.url === file.url)))];
                setFiles(newFileList);
                fileChange(parseFiles(newFileList));
            },
            onCancel() {
                // 用户取消删除，不做任何操作
            },
        });
        // 返回 false，防止默认删除行为
        return false;
    };

    // 自定义上传操作
    const customRequest = async ({file}) => {
        const newFile = await handleUpload(file);
        if (newFile) {
            const newFiles = [...filesRef.current, newFile];
            setFiles(newFiles);
            fileChange(parseFiles(newFiles))
        }
    };

    const handleUpload = async (file) => {
        let result = null;
        if (!beforeUpload()) {
            return null;
        }
        try {
            setLoading(true);
            let md5 = '';
            await FileMD5(file).then((e) => {
                md5 = e;
            }).catch((err) => {
                message.error('计算md5失败');
            });
            const isRepeat = files.some((val) => val.md5 === md5);
            if (isRepeat) {
                message.warning('该文件已上传');
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            await axios({
                method: 'post',
                url: actionUrl,
                data: formData,
                timeout: 30000,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-User-Token': sessionStorage.getItem(localStorageKeys.token) || '',
                },
            }).then((res) => {
                if (res.data && res.data.code === 0) {
                    const respData = res.data.data;
                    const newFile = {...respData, md5};
                    result = newFile;
                } else {
                    message.error('上传失败');
                }
            });
            return result;
        } catch (e) {
            console.info(e)
            message.error(e.message);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const uploadButton = (
        <div style={{height: 20}}>
            <Icon.PlusOutlined/>
            <div style={{ marginTop: 8, }}>点击上传</div>
        </div>
    );

    return (
        <>
            <Spin spinning={loading}>
                <Upload
                    listType="picture-card"
                    fileList={files}
                    onPreview={handlePreview}
                    customRequest={customRequest}
                    onRemove={onRemove}
                    accept={fileType}
                    multiple={multiple}
                >
                    {uploadButton}
                </Upload>
            </Spin>
            <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}
                   animation={false} width="80%" style={{ maxHeight: "90%", top: 30 }} >
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
};
UploadFile.propTypes = {
    fileType: PropTypes.string,
    fileLists: PropTypes.instanceOf(Array),
    fileChange: PropTypes.func.isRequired,
    beforeUpload: PropTypes.func, // 上传前校验，默认返回TRUE不校验
    multiple: PropTypes.bool,
};

UploadFile.defaultProps = {
    fileLists: [],
    fileType: 'image/*',
    beforeUpload: () => true,
    multiple: false,
};

export default UploadFile;
