import React, {useEffect, useRef, useState} from "react";
import wx from "weixin-js-sdk";
import {message} from "antd";
import PropTypes from "prop-types";
import {httpRequest} from "@/utils";

const wxTagStyles = {
    position: 'absolute',  // 绝对定位
    top: 0,                // 覆盖在按钮顶部
    left: 0,               // 覆盖在按钮左侧
    width: '100%',         // 覆盖按钮的宽度
    height: '100%',        // 覆盖按钮的高度
    display: 'block'       // 显示该元素
};

const MiniLaunch = ({ corporationId, onLaunched }) => {

    const weAppRef = useRef(null);
    const [miniPath, setMiniPath] = useState('');

    const getMiniPath = async (corporationId) => {
        try {
            const res = await httpRequest.get(`/client/wxPayRollCard/payrollCard/auth/url/${corporationId}`);
            if (res.code === 0) {
                setMiniPath(res.data);
            } else {
                throw new Error(res.msg);
            }
        } catch (err) {
            message.error(err.message);
        }
    };

    useEffect(() => {
        getMiniPath(corporationId);

        wx.ready(() => {
            weAppRef.current?.addEventListener('launch', onLaunched);
        });

        wx.error((res) => {
            console.error("wx.error", res);
            message.error("加载失败");
        });
    }, []);

    return (
        <>
            <wx-open-launch-weapp
                ref={weAppRef}
                username={process.env.REACT_APP_WECHAT_MINI_PROGRAM_ID}
                path={miniPath}
                env-version={process.env.REACT_APP_WECHAT_MINI_PROGRAM_ENV}
                style={wxTagStyles}
            >
                <script type="text/wxtag-template">
                    <div style={wxTagStyles}/>
                </script>
            </wx-open-launch-weapp>
        </>
    );
};

MiniLaunch.propTypes = {
    corporationId: PropTypes.string,
    onLaunched: PropTypes.func,
};

MiniLaunch.defaultProps = {
    corporationId: "",
};

export default MiniLaunch;
