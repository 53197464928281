import React, { lazy } from 'react';
import example from '@modules/example';
import dashboard from '@/modules/home';
import identity from '@/modules/identity';
import manager from '@/modules/manager';
import withdraw from '@/modules/withdraw';
import detailed from '@/modules/detailed';
import salary from '@/modules/salary';
import salaryDetail from '@/modules/salaryDetail';
import guide from '@/modules/guide';
import advance from '@/modules/advance';
import submitted from '@/modules/submitted';
import contract from '@/modules/contract';
import wxPayrollCard from "@modules/wxPayrollCard";

const NotAuthorized = lazy(() => import('@modules/error/notAuthorized'));

export default [
  dashboard,
  guide,
  example,
  identity,
  manager,
  withdraw,
  detailed,
  salary,
  advance,
  salaryDetail,
  submitted,
  contract,
  wxPayrollCard,
  {
    path: 'not-authorized',
    element: <NotAuthorized />,
  },
];
